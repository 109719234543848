<template>
  <div class="relative">
    <h4 class="text-center mt-3 mb-3">Expense Item</h4>
    <div class=" rounded-lg">
      <div class="flex flex-col md:flex-row md:justify-center ">
        <div class="bg-white md:min-w-1/2 rounded-lg">
          <div class=" p-6 text-center ">
            <p class="md:text-xs text-darkblue mb-2">
              <span class="font-bold"> Item Name:</span>
              {{ expenseItem.expenseItemName }}
            </p>
            <p class="md:text-xs text-darkblue mb-2">
              <span class="font-bold"> Reg. Date:</span>
              {{ regDate }}
            </p>

            <div class="mt-2 ">
              <button
                @click="EditExpenseItem"
                type="button"
                class="bg-green-600 hover:bg-darkblue  mr-2 md:text-xs text-white rounded-lg pt-1 pb-1 pl-4 pr-4"
              >
                Edit
              </button>
              <button
                @click="showDeleteModal = true"
                type="button"
                class="bg-red-600 md:text-xs hover:bg-darkblue  text-white rounded-lg pt-1 pb-1 pl-4 pr-4"
              >
                Delete
              </button>
            </div>
          </div>
          <div v-if="loadingSpinner">
            <svg
              class="ml-auto mr-auto animate-spin"
              width="25"
              height="25"
              viewBox="0 0 50 50"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M48 25C48 12.2975 37.7025 2 25 2"
                stroke="black"
                stroke-width="4"
              />
              <path
                d="M48 25C48 37.7025 37.7025 48 25 48"
                stroke="white"
                stroke-width="4"
              />
              <path
                d="M2 25C2 12.2975 12.2975 2 25 2"
                stroke="white"
                stroke-width="4"
              />
              <path
                d="M25 48C12.2975 48 2 37.7025 2 25"
                stroke="#C9000C"
                stroke-width="4"
              />
            </svg>
          </div>
        </div>
        <div>
          <transition name="slide-fade">
            <NotificationModal
              v-if="showDeleteModal"
              :modalData="modalData"
              @modal-success="DeleteExpenseItem($event)"
              @modal-close="CloseModal($event)"
            />
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import gql from "graphql-tag";
  import NotificationModal from "../../components/NotificationModal.vue";
  import { mapMutations } from "vuex";

  export default {
    name: "SingleExpenseItem",
    props: {},
    NotificationModal,
    components: { NotificationModal },
    apollo: {
      expenseItem: {
        query: gql`
          query expenseItem($id: ID!) {
            expenseItem(id: $id) {
              _id
              expenseItemName
              createdAt
            }
          }
        `,
        variables() {
          return {
            id: this.$route.params.id,
          };
        },

        error(error) {
          this.$emit("show-alert", {
            type: "error",
            message: error.message,
          });
        },
        watchLoading(isLoading, countModifier) {
          if (isLoading === true && countModifier === 1) {
            this.loadingSpinner = true;
            return;
          }

          this.loadingSpinner = false;
        },
      },
    },
    data() {
      return {
        showDeleteModal: false,
        loadingSpinner: false,
        modalData: {
          title: "Delete Expense Item",
          message:
            "Are you sure you want to delete this expense item? You can't get it back if you continue.",
          cancelText: "Cancel",
          successText: "Delete",
          loading: false,
        },

        expenseItem: {
          expenseItemName: "",
        },

        // paginated_table_data: [],
        icons: {
          pen: require("@/assets/imgs/icons/white-pen.svg"),
        },
      };
    },
    computed: {
      regDate: function() {
        const date = new Date(Number(this.expenseItem.createdAt));

        return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
      },
    },
    methods: {
      ...mapMutations(["setBreadcrumbs"]),

      async DeleteExpenseItem() {
        this.modalData.loading = true;

        try {
          const { data, errors } = await this.$apollo.mutate({
            // Query
            mutation: gql`
              mutation($id: ID!) {
                deleteExpenseItem(id: $id) {
                  _id
                  serialId
                  isDeleted
                }
              }
            `,
            // Parameters
            variables: {
              id: this.$route.params.id,
            },
          });

          if (!errors && !!data.deleteExpenseItem._id) {
            this.$emit("show-alert", {
              type: "success",
              message: `Expense Item deleted successfully`,
            });

            return this.$router.push("/app/expenseItems");
          }
          this.modalData.loading = false;

          if (!!errors && data == null) {
            const errorResponse = errors.find((error) => {
              return error.extensions.code == "BAD_USER_INPUT";
            });
            return this.$emit("show-alert", {
              type: "error",
              message: errorResponse.message,
            });
          }

          this.$emit("show-alert", {
            type: "error",
            message: `Error deleting expense item,check your network or contact your developer`,
          });
        } catch (error) {
          this.modalData.loading = false;

          this.$emit("show-alert", {
            type: "error",
            message: `Error deleting expense item,check your network or contact your developer`,
          });
        }
      },
      CloseModal() {
        this.showDeleteModal = false;
        this.modalData.loading = false;
      },

      TableButton() {},
      EditExpenseItem() {
        this.$router.push({
          name: "manageExpenseItem",
          params: { mode: "edit", id: this.$route.params.id },
        });
      },
      FormatTableData(data) {
        this.table_data = data.map((invoice) => {
          return {
            _id: invoice._id,
            invoice_no: invoice.serialId,
            amount: invoice.invoiceTotal,
            customer_name: invoice.customerId.customerName,
            status: invoice.completedPayment === true ? "paid" : "pending",
            btn: "btn",
          };
        });
      },
      ToggleChartSpinner() {
        this.chart_loading = !this.chart_loading;
      },

      ChangeCustomerMode(mode) {
        this.mode = mode;
      },
    },
    watch: {
      getCustomersInvoice(newValue) {
        this.FormatTableData(newValue.result);
      },
    },
    mounted() {
      this.setBreadcrumbs([
        {
          displayName: "Expense Items",
          pathName: "expenseItems",
        },
        {
          displayName: "Single Expense Item",
          pathName: "singleExpenseItem",
          params: { id: this.$route.params.id },
        },
      ]);
    },
  };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  table {
    border-collapse: separate;
    border-spacing: 0 0.5rem;
  }
</style>
